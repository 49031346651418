<template>
  <div class="container is-fluid mt-2">
    <b-table
      :data="stats"
      paginated
      striped
      narrowed
      backend-pagination
      backend-sorting
      :default-sort="[sortField, sortOrder]"
      :default-sort-direction="defaultSortOrder"
      :total="totalStats"
      :loading="loading"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      scrollable
      hoverable
      :per-page="itemsPerPage"
      sticky-header
      height="400px"
      @page-change="onPageChange"
      @sort="onSort"
    >
      <b-table-column v-slot="props" field="id" label="ID" sortable>
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="eventType"
        label="Event Type"
        sortable
      >
        {{ props.row.eventType }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="activityType"
        label="Activity Type"
        sortable
      >
        {{ props.row.activityType }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="apptNumber"
        label="Appointment Number"
        sortable
      >
        {{ props.row.apptNumber }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="activityId"
        label="Activity ID"
        sortable
      >
        {{ props.row.activityId }}
      </b-table-column>

      <b-table-column v-slot="props" field="created" label="Created" sortable>
        {{ formatDateTime(props.row.created) }}
      </b-table-column>

      <b-table-column v-slot="props" field="oldDate" label="Old Date" sortable>
        {{ formatDate(props.row.oldDate) }}
      </b-table-column>

      <b-table-column v-slot="props" field="newDate" label="New Date" sortable>
        {{ formatDate(props.row.newDate) }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="oldTimeSlot"
        label="Old Time Slot"
        sortable
      >
        {{ props.row.oldTimeSlot }}
      </b-table-column>

      <b-table-column
        v-slot="props"
        field="newTimeSlot"
        label="New Time Slot"
        sortable
      >
        {{ props.row.newTimeSlot }}
      </b-table-column>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="sad-tear" size="is-large"> </b-icon>
            </p>
            <p>Nothing here, yet.</p>
          </div>
        </section>
      </template>
    </b-table>
    <div>
      <p v-if="error" class="has-text-danger">{{ error }}</p>
      <div class="buttons">
        <b-button type="is-primary" @click="fetchData()"
          >Retrieve Interaction Stats</b-button
        >
        <b-button type="is-primary" @click="getCsvData()"
          >Download Interaction Stats CSV</b-button
        >
        <b-button type="is-primary" @click="getAuditCsvData()"
          >Download Slot Audit Stats CSV</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { format } from 'date-fns';
import FileSaver from 'file-saver';

import {
  retrieveStats,
  retrieveStatsCsv,
  downloadCsv,
  retrieveAuditCsv,
} from '@/services/rebookStatsService';

export default defineComponent({
  name: 'RebookStatsDisplay',
  data() {
    return {
      stats: [],
      totalReturnedStats: 0,
      totalStats: 0,
      loading: false,
      itemsPerPage: 20,
      error: undefined,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      fileUrl: '',
      csvData: undefined,
    };
  },
  watch: {
    fileUrl: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.pollForFile();
        }
      },
    },
    csvData: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.download(newVal);
        }
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    download(data) {
      const blob = new Blob([data]);
      FileSaver.saveAs(blob, 'rebook-stats-data.zip');
    },
    formatDate(dateString) {
      if (!dateString) return undefined;
      const date = new Date(dateString);
      try {
        return format(date, 'dd-MM-yyyy');
      } catch (err) {
        return undefined;
      }
    },
    formatDateTime(dateString) {
      if (!dateString) return undefined;
      const date = new Date(dateString);
      try {
        return format(date, 'dd-MM-yyyy HH:mm:ss');
      } catch (err) {
        return undefined;
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        this.error = null;
        let offset = 0;
        if (this.stats.length === 0) {
          offset = 0;
        } else {
          offset = (this.page - 1) * this.itemsPerPage;
        }
        const retrieveOptions = {
          totalToRetrieve: this.itemsPerPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          offset: offset,
        };

        const { items, itemCount, overallTotal } = await retrieveStats(
          retrieveOptions,
        );
        this.stats = items;
        this.totalReturnedStats = itemCount;
        this.totalStats = overallTotal;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    async fetchAuditData() {
      this.loading = true;
      try {
        this.error = null;
        let offset = 0;
        if (this.stats.length === 0) {
          offset = 0;
        } else {
          offset = (this.page - 1) * this.itemsPerPage;
        }
        const retrieveOptions = {
          totalToRetrieve: this.itemsPerPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          offset: offset,
        };

        const { items, itemCount, overallTotal } = await retrieveStats(
          retrieveOptions,
        );
        this.stats = items;
        this.totalReturnedStats = itemCount;
        this.totalStats = overallTotal;
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    async getCsvData() {
      const filename = `${new Date().valueOf()}-interactions.zip`;
      this.loading = true;
      try {
        this.error = null;

        this.fileUrl = await retrieveStatsCsv(filename);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    async getAuditCsvData() {
      const filename = `${new Date().valueOf()}-audit.zip`;
      this.loading = true;
      try {
        this.error = null;
        this.fileUrl = await retrieveAuditCsv(filename);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.loading = false;
      }
    },
    onPageChange(page) {
      this.page = page;
      this.fetchData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchData();
    },
    async pollForFile() {
      this.loading = true;
      setTimeout(async () => {
        const data = await downloadCsv({ url: this.fileUrl });
        if (data.byteLength === 0) {
          this.pollForFile();
        } else {
          this.csvData = data;
          this.loading = false;
        }
      }, 5000);
    },
  },
});
</script>
