import axios from 'axios';
import configData from '../config/config.json';
import { getIdToken } from '@/services/auth/index';

const apiClient = axios.create({
  baseURL: configData.omwRebook.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const token = getIdToken();
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    }
    throw new Error('No access token Found');
  },
  (err) => Promise.reject(err),
);

export async function retrieveStats({
  totalToRetrieve,
  sortField,
  sortOrder,
  offset,
}) {
  const params = new URLSearchParams();
  let url = 'stats';

  params.append('totalToRetrieve', totalToRetrieve);
  params.append('sortField', sortField);
  params.append('sortOrder', sortOrder);
  params.append('offset', offset);

  const options = {
    method: 'GET',
    params,
    url,
  };
  const { data } = await apiClient(options);
  return data;
}
export async function retrieveStatsCsv(filename) {
  let url = 'stats/csv';
  const params = new URLSearchParams();
  params.append('filename', filename);

  const options = {
    method: 'GET',
    params,
    url,
  };
  const { data } = await apiClient(options);
  return data.fileUrl;
}

export async function retrieveAuditCsv(filename) {
  const params = new URLSearchParams();
  params.append('filename', filename);

  let url = 'audit/csv';

  const options = {
    method: 'GET',
    params,
    url,
  };
  const { data } = await apiClient(options);
  return data.fileUrl;
}

export async function downloadCsv({ url }) {
  try {
    const response = await axios({
      url,
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/zip',
        'Content-Type': 'application/zip',
      },
    });
    if (response.headers['Content-Length'] == 0) {
      return new ArrayBuffer(0);
    }

    return response.data;
  } catch (error) {
    console.log('axios retrieval error', error);
    return new ArrayBuffer(0);
  }
}
